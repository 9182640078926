<template>
  <div>
    <base-date-picker :calendar-class-instance="calendarClass"
                      :clear-selected-value-trigger="clearSelectedValueTrigger"
                      :layout="configCalendarLayout"
                      :selecting-attribute-name="configType"
                      :selecting-mode="selectingMode"
                      @day-click="handleSelected"
                      @selected-value-update="setSelectedValue"/>
  </div>
</template>

<script>
  import { configCalendarLayout } from '@/helpers/cryoemCalendars';
  import { calendarMixins } from '@/mixins/calendar';
  import BaseDatePicker from '@/components/calendars/BaseDatePicker';

  export default {
    name: 'CryoemCalendarConfigs',
    components: { BaseDatePicker },
    mixins: [calendarMixins],
    model: { prop: 'actionOnSelected' },
    props: {
      actionOnSelected: {
        type: Object,
        required: true,
      },
      configType: {
        type: String,
        required: true,
      },
      selectingMode: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        configCalendarLayout,
        environment: 'cryoem',
        forceRender: false,
        selectedValue: null,
        calendarClass: {},
        clearSelectedValueTrigger: false,
      };
    },
    computed: {
      indexOfSelectingInAttributes() {
        return this.calendarClass.findAttrIndex(`${this.configType}Selecting`);
      },
    },
    watch: {
      'actionOnSelected.cancel': {
        handler() {
          this.handleActionOnSelected('cancel');
        },
      },
      'actionOnSelected.submit': {
        handler() {
          this.handleActionOnSelected('submit');
        },
      },
      configType: {
        handler() {
          this.calendarClass.setStoredConfig();
        },
      },
    },
    async created() {
      this.calendarClass = await this.getClassCalendar(this.environment, true);
    },
    methods: {
      setSelectedValue(data) {
        this.selectedValue = data;
      },
      handleSelected(event) {
        const clickedDay = event.id;
        if (this.selectingMode === 'range') {
          this.handleRangeSelect();
        } else {
          this.handleSingleSelect(clickedDay);
        }
      },
      handleRangeSelect() {
        if (this.selectedValue) {
          const selectedStartDay = this.dateToStringFormat(this.selectedValue.start);
          const selectedEndDay = this.dateToStringFormat(this.selectedValue.end);
          const datesObject = {
            start: selectedStartDay,
            end: selectedEndDay,
          };
          this.handleAddingDatesToAttributes(datesObject);
          this.clearSelectedValue();
        }
      },
      handleAddingDatesToAttributes(datesObject) {
        if (!this.calendarClass.areDatesInConflictWithAttrs(datesObject)) {
          this.calendarClass.addNewDateToAttrDates(datesObject, this.indexOfSelectingInAttributes);
        } else {
          this.$notify({ type: 'error', title: 'Time period cannot include previously selected dates.' });
        }
      },
      clearSelectedValue() {
        this.clearSelectedValueTrigger = !this.clearSelectedValueTrigger;
      },
      handleSingleSelect(clickedDay) {
        const selectedDay = this.dateToStringFormat(this.selectedValue);
        if (this.selectedValue && selectedDay === clickedDay) {
          const datesObject = {
            start: selectedDay,
            end: selectedDay,
          };
          this.handleAddingDatesToAttributes(datesObject);
        } else {
          this.$notify({ type: 'error', title: 'Disabled date' });
        }
        this.clearSelectedValue();
      },
      async handleActionOnSelected(actionType) {
        if (this.actionOnSelected[actionType] === true) {
          await this.callActionOnSelected(actionType);
          const newActionOnSelected = JSON.parse(JSON.stringify(this.actionOnSelected));
          newActionOnSelected[actionType] = false;
          this.$emit('input', newActionOnSelected);
        }
      },
      async callActionOnSelected(actionType) {
        if (actionType === 'submit') {
          await this.submitSelectedValues();
        } else {
          this.calendarClass.setStoredConfig();
        }
      },
      async submitSelectedValues() {
        const dispatchData = {
          environment: this.environment,
          configName: this.configType,
          dates: this.calendarClass.attributes[this.indexOfSelectingInAttributes].dates,
        };
        await this.$store.dispatch('calendar/submitCalendarConfigDatesWithNotify', dispatchData);
        this.calendarClass.loadConfig(this.environment);
      },
    },
  };
</script>

<style scoped>

</style>
