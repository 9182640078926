<template>
  <div>
    <v-date-picker v-if="calendarClassInstance"
                   :key="renderTrigger"
                   ref="datePickerRef"
                   v-model="selectedValue"
                   :attributes="calendarClassInstance.attributes"
                   :color="selectingColor"
                   :columns="computedLayout.columns"
                   :disabled-dates="computedDisabledDates"
                   :first-day-of-week="2"
                   :is-expanded="computedLayout.isExpanded"
                   :is-range="isRange"
                   :min-page="$attrs['min-page']"
                   :rows="computedLayout.rows"
                   :step="computedLayout.columns"
                   locale="en"
                   @dayclick="emitDayClickAndSelected"/>
  </div>
</template>

<script>
  import { markingsTypes } from '@/helpers/cryoemCalendars';

  export default {
    name: 'BaseDatePicker',
    props: {
      calendarClassInstance: {
        type: Object,
        required: true,
      },
      clearSelectedValueTrigger: {
        type: Boolean,
        default: false,
      },
      datesRange: {
        type: Object,
        default: () => ({}),
      },
      renderTrigger: {
        type: Boolean,
        default: false,
      },
      layout: {
        type: Object,
        required: true,
      },
      selectingAttributeName: {
        type: String,
        default: 'user',
      },
      selectingMode: {
        type: String,
        default: 'single',
      },
    },
    data() {
      return {
        selectedValue: this.value,
        disabledDates: [],
        markingsTypes,
      };
    },
    computed: {
      computedDisabledDates() {
        if (!this.isEmpty(this.datesRange)) {
          return this.disabledDates.concat([
            { end: this.datesRange.start },
            { start: this.datesRange.end },
          ]);
        }
        return this.disabledDates;
      },
      computedLayout() {
        return this.$screens(
          this.layout,
        );
      },
      isRange() {
        return this.selectingMode === 'range';
      },
      selectingColor() {
        return this.getMarkHighlight(this.selectingAttributeName);
      },
    },
    watch: {
      'calendarClassInstance.allConfigs': {
        handler() {
          this.clearAll();
          this.setAll();
        },
        deep: true,
      },
      'calendarClassInstance.allReservations': {
        handler() {
          this.clearAll();
          this.setAll();
        },
        deep: true,
      },
      clearSelectedValueTrigger() {
        this.clearDeepSelectedValue();
      },
      selectedValue() {
        this.$emit('selected-value-update', this.selectedValue);
      },
    },
    methods: {
      addConfigsToAttrsAndDisabled() {
        Object.entries(this.calendarClassInstance.allConfigs).forEach(([key, val]) => {
          if (key === 'weekdays') {
            this.disabledDates.push({ [key]: val });
          } else {
            this.addDatesToAttrsAndDisabled(val, key);
          }
        });
      },
      addDatesToAttrsAndDisabled(data, key) {
        this.createAttribute(key);
        const attrIdx = this.calendarClassInstance.findAttrIndex(key);
        data.forEach((el) => {
          this.disabledDates.push(el);
          this.calendarClassInstance.addNewDateToAttrDates(el, attrIdx);
        });
      },
      clearAll() {
        this.calendarClassInstance.clearAttributes();
        this.disabledDates = [];
        this.clearDeepSelectedValue();
      },
      clearDeepSelectedValue() {
        this.selectedValue = null;
        // eslint-disable-next-line no-underscore-dangle
        this.$refs.datePickerRef.value_ = this.selectedValue;
      },
      createAttribute(key, isSelectingAttr = false) {
        const keyColor = this.getMarkHighlight(key);
        const keyName = isSelectingAttr ? `${key}Selecting` : key;
        this.calendarClassInstance.addNewObjectToAttrs(keyName, keyColor);
      },
      emitDayClickAndSelected(data) {
        this.$emit('selected-value-update', this.selectedValue);
        this.$emit('day-click', data);
      },
      getMarkHighlight(mark) {
        return this.markingsTypes.find((el) => el.value === mark).highlight;
      },
      setAll() {
        this.addConfigsToAttrsAndDisabled();
        this.setReservations();
        this.setSelectingAttribute();
        this.disabledDates.push({ end: (new Date()).toString() });
      },
      setReservations() {
        this.addDatesToAttrsAndDisabled(this.calendarClassInstance.allReservations, 'taken');
      },
      setSelectingAttribute() {
        // The colors of attributes are set cascade. Therefore, it is really important to add
        // this.selectingAttributeName at the end of the attributes to prevent color overwrite.
        this.createAttribute(this.selectingAttributeName, true);
      },
    },
  };
</script>

<style scoped>

</style>
