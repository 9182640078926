<template>
  <b-row>
    <b-col>
      <b-form>
        <leaf-styled-radio-buttons v-model="markingType"
                                   :disabled="false"
                                   :options="markingTypesOptions"
                                   selected_at_the_beginning="machine_time"/>

        <div class="column-container">
          <cryoem-calendar-configs :key="renderCalendarTrigger"
                                   v-model="actionOnSelected"
                                   :config-type="markingType"
                                   :selecting-mode="mode"/>
          <b-row align-h="between"
                 class="mt-2 mb-4">

            <b-col class="mb-2"
                   cols="4">
              <extended-radio-group v-model="mode"
                                    :options="modeOptions"/>
            </b-col>

            <b-col cols="auto">
              <b-btn class="calendar-btn mr-2"
                     variant="info"
                     @click="submit"
                     v-text="submitText"/>

              <b-btn class="calendar-btn"
                     @click="clear">
                Cancel
              </b-btn>
              <b-col class="mt-5">
                <hr>
                <h5 class="p-form-label">Start Days for Experiment</h5>
                <div class="p-form-description">
                  <ul>
                    <li>The days selected below determine when an experiment can start.</li>
                    <li>Users will only be able to choose days that match the weekdays you have selected.</li>
                  </ul>
                </div>
                <multiselect v-model="weekdaysAvailableForUser"
                             :options="getWeekdaysAvailableForUser()"
                             class="mb-2"/>
                <b-btn variant="info"
                       @click="submitWeekdaysConfiguration">
                  Submit weekdays
                </b-btn>
              </b-col>
            </b-col>

          </b-row>
        </div>

      </b-form>
    </b-col>
  </b-row>
</template>

<script>
  import LeafStyledRadioButtons from '@/components/LeafStyledRadioButtons';
  import CryoemCalendarConfigs from '@/components/calendars/cryoem/CryoemCalendarConfigs';
  import { getAvailableOrExcludedWeekdays, markingsTypesWithoutUser, weekdaysMapping } from '@/helpers/cryoemCalendars';
  import ExtendedRadioGroup from '@/components/extended-radio-group';
  import Multiselect from '@/components/multiselect';
  import { adaptListOfNamesToMultiselectOptions } from '@/helpers';

  export default {
    name: 'cryoem_dates_exclude_form',
    components: {
      Multiselect,
      LeafStyledRadioButtons,
      CryoemCalendarConfigs,
      ExtendedRadioGroup,
    },
    props: {
      renderCalendarTrigger: {
        type: Boolean,
        default: false,
      },
      calendarConfig: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        mode: 'range',
        modeOptions: [
          { value: 'range', text: 'Range', buttonVariant: 'outline-info' },
          { value: 'multiple', text: 'Single', buttonVariant: 'outline-info' },
        ],
        markingType: 'machine_time',
        actionOnSelected: { submit: false, cancel: false },
        markingsTypesWithoutUser,
        weekdaysAvailableForUser: [],
      };
    },
    computed: {
      markingTypesOptions() {
        return this.markingsTypesWithoutUser.map((markType) => {
          const markTypeCopy = { ...markType };
          if (markTypeCopy.value === 'taken') {
            markTypeCopy.disabled = true;
          }
          return markTypeCopy;
        });
      },
      submitText() {
        const markingText = this.markingTypesOptions.find((el) => el.value === this.markingType).text;
        return `Submit ${markingText}`;
      },
    },
    watch: {
      'actionOnSelected.submit': {
        handler(curVal, oldVal) {
          if (curVal === false && oldVal === true) {
            this.$emit('config_changed');
          }
        },
      },
      calendarConfig: {
        handler(newValue) {
          if (newValue.weekdays) {
            this.loadCurrentCryoemCalendarWeekdaysConfig();
          }
        },
      },
    },
    methods: {
      clear() {
        this.setActionOnSelected('cancel', true);
      },
      setActionOnSelected(actionType, value) {
        this.actionOnSelected[actionType] = value;
      },
      submit() {
        this.setActionOnSelected('submit', true);
      },
      submitWeekdaysConfiguration() {
        this.updateCryoemCalendarWeekdaysConfig(this.weekdaysAvailableForUser);
      },
      getWeekdaysAvailableForUser() {
        return adaptListOfNamesToMultiselectOptions(weekdaysMapping);
      },
      loadCurrentCryoemCalendarWeekdaysConfig() {
        this.weekdaysAvailableForUser = getAvailableOrExcludedWeekdays(
          this.calendarConfig.weekdays,
          true,
        );
      },
      updateCryoemCalendarWeekdaysConfig(newWeekdaysConfiguration) {
        const weekdaysNumberRepresentation = {
          weekdays: getAvailableOrExcludedWeekdays(
            newWeekdaysConfiguration,
            false,
          ),
        };
        this.axios.put('/config/cryoem_calendar/weekdays', weekdaysNumberRepresentation)
          .then((resp) => {
            if (resp.data.modified) {
              this.$emit('config_changed');
              this.$notify({ type: 'success', title: 'Updated weekdays changes!' });
            } else {
              this.$notify({ type: 'warn', title: 'No weekdays changes detected!' });
            }
          }).catch((error) => {
            this.$notify({ type: 'error', title: error.response.data.err });
          });
      },
    },
  };
</script>

<style scoped>
  .calendar-btn {
    min-width: 13.5rem;
  }

  .column-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
