<template>
  <b-card class="mb-1"
          no-body>
    <b-card-header class="p-0"
                   header-tag="header"
                   role="tab">
      <b-button v-b-toggle.new_reservation
                block
                variant="info">
        Add new reservation
      </b-button>
    </b-card-header>
    <b-collapse id="new_reservation"
                accordion="new_reservation"
                role="tabpanel">
      <b-card-body>

        <b-row v-if="Object.keys(proposalsObjectWithDocumentIdKeys).length">
          <b-col md="2"
                 sm="3"
                 xl="2">

            <span>Select Proposal</span>
            <b-form-select v-model="selected"
                           :options="Object.keys(proposalsObjectWithDocumentIdKeys)"/>
            <h5 class="info-text mt-3"
                v-text="proposalShiftsText"/>

          </b-col>

          <b-col class="mt-4">
            <cryoem-calendar-reservations :key="renderReservationCalendarTrigger"
                                          v-model="submitSelected"
                                          :proposal-id="proposalId"
                                          :proposal-experiment-length-in-days="proposalExperimentLengthInDays"
                                          layout-type="managerDashboard"
                                          @number-of-all-reserved-shifts-update="numberOfReservedShifts = $event"
                                          @number-of-all-shifts-update="numberOfAllShifts = $event"/>
          </b-col>
          <b-col class="mt-4">
            <b-btn :disabled="!proposalId"
                   class="submit"
                   variant="info"
                   @click="handleSubmitSelected()">
              Add reservation
              <span v-if="selected"> for {{ selected }}</span>
            </b-btn>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col>
            <h5 class="info-text">
              No positively evaluated applications have yet been submitted to this call.
            </h5>
          </b-col>
        </b-row>

      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
  import CryoemCalendarReservations from '@/components/calendars/cryoem/CryoemCalendarReservations';

  export default {
    name: 'cryoem_reservations_form',
    components: { CryoemCalendarReservations },
    props: {
      proposals: {
        type: Array,
        required: true,
      },
      renderCalendarTrigger: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data() {
      return {
        selected: null,
        renderReservationCalendarTrigger: true,
        submitSelected: false,
        numberOfAllShifts: 0,
        numberOfReservedShifts: 0,
      };
    },
    computed: {
      proposalsObjectWithDocumentIdKeys() {
        return Object.fromEntries(this.proposals.filter((el) => (
          this.filterProposalsForReservation(el)))
          .map((el) => [el.document_specification.document_id, el]));
      },
      proposalId() {
        if (this.selected) {
          return this.proposalsObjectWithDocumentIdKeys[this.selected]._id.$oid;
        }
        return '';
      },
      proposalExperimentLengthInDays() {
        if (this.selected) {
          const proposalLengthOfShiftInDays = this.proposalsObjectWithDocumentIdKeys[
            this.selected
          ].general.length_of_shift_in_days;
          if (proposalLengthOfShiftInDays) {
            if (proposalLengthOfShiftInDays === '2.5') {
              return 3;
            }
            if (proposalLengthOfShiftInDays === '1') return 2;
          }
        }
        return 0;
      },
      shiftLengthLabel() {
        if (this.proposalExperimentLengthInDays === 2) {
          return '1-day';
        }
        if (this.proposalExperimentLengthInDays === 3) {
          return '2.5-day';
        }
        return '';
      },
      proposalShiftsText() {
        if (this.selected) {
          return `Selected proposal has ${this.numberOfReservedShifts} of ${this.numberOfAllShifts}
          ${this.shiftLengthLabel} shifts reserved`;
        }
        return '';
      },
    },
    methods: {
      filterProposalsForReservation(proposal) {
        if (this.hasProperty(proposal, 'management_evaluation')) {
          return (this.hasProperty(proposal.management_evaluation, 'number_of_shifts')
            && proposal.management_evaluation.number_of_shifts > 0);
        }
        // for cryo-EM proposals from Spring 2024 call and CERIC proposals
        return (this.hasProperty(proposal, 'final_grade')
          && this.hasProperty(proposal.final_grade, 'number_of_shifts')
          && proposal.final_grade.number_of_shifts > 0);
      },
      handleSubmitSelected() {
        this.submitSelected = true;
      },
      refreshCryoemCalendar() {
        this.renderReservationCalendarTrigger = !this.renderReservationCalendarTrigger;
      },
    },
    watch: {
      renderCalendarTrigger() {
        this.refreshCryoemCalendar();
        this.selected = '';
      },
      submitSelected: {
        handler(curVal, oldVal) {
          if (oldVal === true && curVal === false) {
            this.$emit('get_proposals');
          }
        },
      },

    },
  };
</script>

<style lang="scss"
       scoped>
  .submit {
    width: 15em;
  }

  .submit:disabled {
    border-color: gray !important;
    background-color: gray !important;
    color: whitesmoke !important;
  }

  .info-text {
    color: $color-blue-info;
  }
</style>
